export default defineAppConfig({
  ui: {
    primary: 'deckmatchPrimary',
    gray: 'deckmatchGray',
    red: 'error',
    green: 'success',
    notifications: {
      position: 'top-0 bottom-[unset]'
    }
  }
})