
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as authorize6aqTeEDJMXMeta } from "/vercel/path0/apps/web/pages/authorize.vue?macro=true";
import { default as _91deckId_93VS3hqvYwmhMeta } from "/vercel/path0/apps/web/pages/deals/[deckId].vue?macro=true";
import { default as indexZqlAvGc1GMMeta } from "/vercel/path0/apps/web/pages/index.vue?macro=true";
import { default as loginK3GPdXgpRiMeta } from "/vercel/path0/apps/web/pages/login.vue?macro=true";
import { default as no_accessCbvIh4jqjHMeta } from "/vercel/path0/apps/web/pages/no_access.vue?macro=true";
import { default as custom_fieldsxz6MvlPPJzMeta } from "/vercel/path0/apps/web/pages/rules/custom_fields.vue?macro=true";
import { default as geography29WbabP21NMeta } from "/vercel/path0/apps/web/pages/rules/geography.vue?macro=true";
import { default as indexuhXGBsK4C3Meta } from "/vercel/path0/apps/web/pages/rules/index.vue?macro=true";
import { default as industriesJIjExW8kyyMeta } from "/vercel/path0/apps/web/pages/rules/industries.vue?macro=true";
import { default as nuance3WU0kxHFbsMeta } from "/vercel/path0/apps/web/pages/rules/nuance.vue?macro=true";
import { default as rulesJKLCftMG1CMeta } from "/vercel/path0/apps/web/pages/rules.vue?macro=true";
import { default as indexOh59ehnYIIMeta } from "/vercel/path0/apps/web/pages/settings/index.vue?macro=true";
import { default as stagesSb8WyDbXZ1Meta } from "/vercel/path0/apps/web/pages/settings/stages.vue?macro=true";
import { default as teamx5f4S6FlzqMeta } from "/vercel/path0/apps/web/pages/settings/team.vue?macro=true";
import { default as settingstwTvIciRODMeta } from "/vercel/path0/apps/web/pages/settings.vue?macro=true";
import { default as geography7sjNnq3CcqMeta } from "/vercel/path0/apps/web/pages/signup/geography.vue?macro=true";
import { default as indexBpj6mt2rkeMeta } from "/vercel/path0/apps/web/pages/signup/index.vue?macro=true";
import { default as industries15XZK62lePMeta } from "/vercel/path0/apps/web/pages/signup/industries.vue?macro=true";
import { default as modelseKVUWESf12Meta } from "/vercel/path0/apps/web/pages/signup/models.vue?macro=true";
import { default as nuancee7ZOKTQ3i9Meta } from "/vercel/path0/apps/web/pages/signup/nuance.vue?macro=true";
import { default as signupVJM412Zb3EMeta } from "/vercel/path0/apps/web/pages/signup.vue?macro=true";
import { default as statusZGdYFTt0EHMeta } from "/vercel/path0/apps/web/pages/status.vue?macro=true";
import { default as verify_emailfNvDbWEwAjMeta } from "/vercel/path0/apps/web/pages/verify_email.vue?macro=true";
export default [
  {
    name: "authorize",
    path: "/authorize",
    component: () => import("/vercel/path0/apps/web/pages/authorize.vue")
  },
  {
    name: "deals-deckId",
    path: "/deals/:deckId()",
    component: () => import("/vercel/path0/apps/web/pages/deals/[deckId].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/apps/web/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginK3GPdXgpRiMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/login.vue")
  },
  {
    name: "no_access",
    path: "/no_access",
    meta: no_accessCbvIh4jqjHMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/no_access.vue")
  },
  {
    name: "Rules",
    path: "/rules",
    meta: rulesJKLCftMG1CMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/rules.vue"),
    children: [
  {
    name: "rules-custom_fields",
    path: "custom_fields",
    component: () => import("/vercel/path0/apps/web/pages/rules/custom_fields.vue")
  },
  {
    name: "rules-geography",
    path: "geography",
    component: () => import("/vercel/path0/apps/web/pages/rules/geography.vue")
  },
  {
    name: "rules",
    path: "",
    component: () => import("/vercel/path0/apps/web/pages/rules/index.vue")
  },
  {
    name: "rules-industries",
    path: "industries",
    component: () => import("/vercel/path0/apps/web/pages/rules/industries.vue")
  },
  {
    name: "rules-nuance",
    path: "nuance",
    component: () => import("/vercel/path0/apps/web/pages/rules/nuance.vue")
  }
]
  },
  {
    name: "Settings & Account Admin",
    path: "/settings",
    meta: settingstwTvIciRODMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/settings.vue"),
    children: [
  {
    name: "settings",
    path: "",
    component: () => import("/vercel/path0/apps/web/pages/settings/index.vue")
  },
  {
    name: "settings-stages",
    path: "stages",
    component: () => import("/vercel/path0/apps/web/pages/settings/stages.vue")
  },
  {
    name: "settings-team",
    path: "team",
    component: () => import("/vercel/path0/apps/web/pages/settings/team.vue")
  }
]
  },
  {
    name: signupVJM412Zb3EMeta?.name,
    path: "/signup",
    meta: signupVJM412Zb3EMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/signup.vue"),
    children: [
  {
    name: "signup-geography",
    path: "geography",
    component: () => import("/vercel/path0/apps/web/pages/signup/geography.vue")
  },
  {
    name: "signup",
    path: "",
    component: () => import("/vercel/path0/apps/web/pages/signup/index.vue")
  },
  {
    name: "signup-industries",
    path: "industries",
    component: () => import("/vercel/path0/apps/web/pages/signup/industries.vue")
  },
  {
    name: "signup-models",
    path: "models",
    component: () => import("/vercel/path0/apps/web/pages/signup/models.vue")
  },
  {
    name: "signup-nuance",
    path: "nuance",
    component: () => import("/vercel/path0/apps/web/pages/signup/nuance.vue")
  }
]
  },
  {
    name: "status",
    path: "/status",
    meta: statusZGdYFTt0EHMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/status.vue")
  },
  {
    name: "verify_email",
    path: "/verify_email",
    meta: verify_emailfNvDbWEwAjMeta || {},
    component: () => import("/vercel/path0/apps/web/pages/verify_email.vue")
  }
]